import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () =>
                import('@/views/Home.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Anasayfa',
                breadcrumb: [{
                    text: 'Anasayfa',
                    active: true,
                },],
            },
        },
        {
            path: '/chats',
            name: 'chats-page',
            component: () =>
                import('@/views/Apps/chat/Chat.vue'),
            meta: {
                contentRenderer: 'sidebar-left',
                authRequire: true,
                permission: 'TEACHER',
                breadcrumb: null
            },
        },
        {
            path: '/second-page',
            name: 'second-page',
            component: () =>
                import('@/views/SecondPage.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Second Page',
                breadcrumb: [{
                    text: 'Second Page',
                    active: true,
                },],
            },
        },
        {
            path: '/company',
            name: 'company-page',
            component: () =>
                import('@/views/SuperAdmin/CompanyPage.vue'),
            meta: {
                authRequire: true,
                permission: 'SUPERADMIN',
                pageTitle: 'Firmalar',
                breadcrumb: [{
                    text: 'Firma İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/users',
            name: 'users-page',
            component: () =>
                import('@/views/SuperAdmin/UsersPage.vue'),
            meta: {
                authRequire: true,
                permission: 'SUPERADMIN',
                pageTitle: 'Kullanıcılar',
                breadcrumb: [{
                    text: 'Kullanıcı İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/packages',
            name: 'packages-page',
            component: () =>
                import('@/views/SuperAdmin/PackagePage.vue'),
            meta: {
                authRequire: true,
                permission: 'SUPERADMIN',
                pageTitle: 'Paketler',
                breadcrumb: [{
                    text: 'Paket İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/modules',
            name: 'modules-page',
            component: () =>
                import('@/views/SuperAdmin/ModulePage.vue'),
            meta: {
                authRequire: true,
                permission: 'SUPERADMIN',
                pageTitle: 'Modüller',
                breadcrumb: [{
                    text: 'Modül İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/schools',
            name: 'schools-page',
            component: () =>
                import('@/views/SuperAdmin/SchoolPage.vue'),
            meta: {
                authRequire: true,
                permission: 'SUPERADMIN',
                pageTitle: 'Okullar',
                breadcrumb: [{
                    text: 'Okul İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/exams',
            name: 'exams-page',
            component: () =>
                import('@/views/Exam/ExamsPage.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Deneme Sınavları',
                breadcrumb: [{
                    text: 'Deneme Sınavı İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/report-card',
            name: 'report-card-page',
            component: () =>
                import('@/views/Exam/ReportCard.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Deneme Sınav Karneleri',
                breadcrumb: [{
                    text: 'Deneme Sınav Karneleri',
                    active: true,
                },],
            },
        },
        {
            path: '/report-card-item/:id',
            name: 'report-card-item-page',
            component: () =>
                import('@/views/Exam/ReportCardDetail.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Karne Detayları',
                breadcrumb: [{
                    text: 'Karne Detayları',
                    active: true,
                },],
            },
        },
        {
            path: '/exams/lesson/:id',
            name: 'exam-lesson-page',
            component: () =>
                import('@/views/Exam/ExamLessonPage.vue'),
            meta: {
                authRequire: true,
                pageTitle: 'Deneme Sınavları',
                breadcrumb: [{
                    text: 'Deneme Sınavı Dersleri',
                    active: true,
                },],
            },
        },
        {
            path: '/exams/question/:examId/:lessonId/:id',
            name: 'exam-question-page',
            component: () =>
                import('@/views/Exam/ExamQuestionPage.vue'),
            meta: {
                isExcellUpload:true,
                authRequire: true,
                pageTitle: 'Deneme Sınavı Ders',
                breadcrumb: [{
                    text: 'Deneme Sınavı Soruları',
                    active: true,
                },],
            },
        },
        {
            path: '/c/users',
            name: 'company-users-page',
            component: () =>
                import('@/views/Company/UsersPage.vue'),
            meta: {
                authRequire: true,
                permission: 'COMPANYADMIN',
                pageTitle: 'Kullanıcılar',
                breadcrumb: [{
                    text: 'Kullanıcı İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/c/branchs',
            name: 'company-branchs-page',
            component: () =>
                import('@/views/Company/BranchsPage.vue'),
            meta: {
                authRequire: true,
                permission: 'COMPANYADMIN',
                pageTitle: 'Şubeler',
                breadcrumb: [{
                    text: 'Şube İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/c/hooks',
            name: 'company-hooks-page',
            component: () =>
                import('@/views/Company/Hooks.vue'),
            meta: {
                authRequire: true,
                permission: 'COMPANYADMIN',
                pageTitle: 'Senkronizasyon',
                breadcrumb: [{
                    text: 'Veri Senkronizasyon',
                    active: true,
                },],
            },
        },
        {
            path: '/c/hooksdetails/:uid/:branchId',
            name: 'company-hooks-details-page',
            component: () =>
                import('@/views/Company/HookDetails.vue'),
            meta: {
                authRequire: true,
                permission: 'COMPANYADMIN',
                pageTitle: 'Senkronizasyon',
                breadcrumb: [{
                    text: 'Veri Senkronizasyon',
                    active: true,
                },],
            },
        },
        {
            path: '/b/classes',
            name: 'branch-classes-page',
            component: () =>
                import('@/views/Branch/ClassPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Sınıflar',
                breadcrumb: [{
                    text: 'Sınıf İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/lessons',
            name: 'branch-lessons-page',
            component: () =>
                import('@/views/Branch/LessonPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Dersler',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/entegration',
            name: 'branch-entegration-page',
            component: () =>
                import('@/views/Entegrations/EntegrationPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Entegrasyonlar',
                breadcrumb: [{
                    text: 'Entegrasyon İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/syllabus',
            name: 'branch-syllabus-page',
            component: () =>
                import('@/views/Branch/SyllabusPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Ders Programı',
                breadcrumb: [{
                    text: 'Ders Programı İşlemleri',
                    active: true,
                },],
            },
        },      
          {
            path: '/b/attendance',
            name: 'branch-attendence',
            component: () =>
                import('@/views/Branch/AttendancePage.vue'),
            meta: {
                authRequire: true,
                isSave: false,
                permission: 'BRANCHADMIN',
                pageTitle: 'Yoklama',
                breadcrumb: [{
                    text: 'Yoklama',
                    active: true,
                },],
            },
        },
        {
            path: '/b/teachers',
            name: 'branch-teachers-page',
            component: () =>
                import('@/views/Branch/UsersPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Öğretmenler',
                breadcrumb: [{
                    text: 'Öğretmen İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/students',
            name: 'branch-students-page',
            component: () =>
                import('@/views/Branch/StudentPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Öğrenciler',
                isExcellUpload: true,
                breadcrumb: [{
                    text: 'Öğrenci İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/guardians',
            name: 'branch-guardians-page',
            component: () =>
                import('@/views/Branch/GuardianPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Veliler',
                isExcellUpload: true,
                breadcrumb: [{
                    text: 'Veli İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/b/surveys',
            name: 'branch-surveys-page',
            component: () =>
                import('@/views/Branch/SurveyPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Anketler'
            },
        },
        {
            path: '/b/homework-report',
            name: 'branch-homework-page',
            component: () =>
                import('@/views/Branch/HomeworksReport.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Ödev Raporları'
            },
        },
        {
            path: '/t/weekly-report',
            name: 'teacher-weekly-page',
            component: () =>
                import('@/views/Branch/WeeklyReport.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Haftalık Durum Raporları'
            },
        },
        {
            path: '/b/weekly-report',
            name: 'branch-weekly-page',
            component: () =>
                import('@/views/Branch/WeeklyReport.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Haftalık Durum Raporları'
            },
        },
        {
            path: '/b/surveyGroups/:id',
            name: 'branch-survey-groups-page',
            component: () =>
                import('@/views/Branch/SurveyGroupPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Anket Soru Grupları'
            },
        },
        {
            path: '/b/surveyQuestion/:surveyId/:groupId',
            name: 'branch-survey-question-page',
            component: () =>
                import('@/views/Branch/SurveyQuestion.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Grup soruları'
            },
        },
        {
            path: '/b/contacts',
            name: 'branch-contact-page',
            component: () =>
                import('@/views/Branch/ContactPage.vue'),
            meta: {
                authRequire: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Geri Bildirimler'
            },
        },
        {
            path: '/t/students',
            name: 'teacher-students-page',
            component: () =>
                import('@/views/Teacher/StudentPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Öğrencilerim',
                breadcrumb: [{
                    text: 'Öğrenci İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/interview',
            name: 'teacher-interview-page',
            component: () =>
                import('@/views/Teacher/InterviewPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Rehberlik',
                breadcrumb: [{
                    text: 'Görüşme İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/reply',
            name: 'teacher-reply-page',
            component: () =>
                import('@/views/Teacher/ReplyPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Soru Cevap',
                breadcrumb: [{
                    text: 'Gelen Sorular',
                    active: true,
                },],
            },
        },
        {
            path: '/t/attendance',
            name: 'teacher-attendance-page',
            component: () =>
                import('@/views/Teacher/AttendancePage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Yoklama',
                breadcrumb: [{
                    text: 'Öğrenci Yoklaması',
                    active: true,
                },],
            },
        },
        {
            path: '/t/lessons',
            name: 'teacher-lessons-page',
            component: () =>
                import('@/views/Teacher/LessonPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Derslerim',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/addHomeWork',
            name: 'teacher-add-homework-page',
            component: () =>
                import('@/views/Teacher/AddHomeWork.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Ev Ödevi',
                breadcrumb: [{
                    text: 'Toplu Ev Ödevi İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/weeklyPlan',
            name: 'teacher-add-weeklyplan-page',
            component: () =>
                import('@/views/Teacher/AddWeeklyPlan.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Haftalık Plan',
                breadcrumb: [{
                    text: 'Haftalık Plan İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/sendWeeklyPlan',
            name: 'teacher-send-weeklyplan-page',
            component: () =>
                import('@/views/Teacher/SendWeeklyPlan.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Haftalık Ödev Gönderme',
                breadcrumb: [{
                    text: 'Haftalık Ödev İşlemleri',
                    active: true,
                },],
            },
        },
        {
            path: '/t/chilLesson/:classId/:lessonId',
            name: 'teacher-child-lessons-page',
            component: () =>
                import('@/views/Teacher/ChildLessonPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'TEACHER',
                pageTitle: 'Derslerim',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Alt Ders İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/topic/:childLessonId',
            name: 'teacher-topic-page',
            component: () =>
                import('@/views/Teacher/TopicPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'TEACHER',
                pageTitle: 'Derslerim',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Alt Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Konu İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/videoGroup/:topicId',
            name: 'teacher-videogroup-page',
            component: () =>
                import('@/views/Teacher/VideoGroupPage.vue'),
            meta: {
                authRequire: true,
                isSave: true,
                permission: 'TEACHER',
                pageTitle: 'Derslerim',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Alt Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Konu İşlemleri',
                    active: true,
                },
                {
                    text: 'Video Grup İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/selection/:videoGroupId',
            name: 'teacher-selection-page',
            component: () =>
                import('@/views/Teacher/SelectionPage.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: true,
                isSetupButton: true,
                permission: 'TEACHER',
                pageTitle: 'Derslerim',
                breadcrumb: [{
                    text: 'Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Alt Ders İşlemleri',
                    active: true,
                },
                {
                    text: 'Konu İşlemleri',
                    active: true,
                },
                {
                    text: 'Video Grup İşlemleri',
                    active: true,
                },
                {
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/videos/:videoGroupId',
            name: 'teacher-videos-page',
            component: () =>
                import('@/views/Teacher/VideoPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Videolar',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Video İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/documents/:videoGroupId',
            name: 'teacher-documents-page',
            component: () =>
                import('@/views/Teacher/DocumentPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Dökümanlar',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Döküman İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/exams/:videoGroupId',
            name: 'teacher-exams-page',
            component: () =>
                import('@/views/Teacher/ExamPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Konu Testleri',
                isPdfButton: true,
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Konu Test İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/gains/:videoGroupId',
            name: 'teacher-gains-page',
            component: () =>
                import('@/views/Teacher/GainPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Kazanımlar',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Kazanım İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/homeworks/:videoGroupId',
            name: 'teacher-homeworks-page',
            component: () =>
                import('@/views/Teacher/HomeWorkPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Ev Ödevleri',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Ev Ödev İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/pages/:videoGroupId',
            name: 'teacher-pages-page',
            component: () =>
                import('@/views/Teacher/PageModulePage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Sayfalar',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Sayfa İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/liveLessons',
            name: 'teacher-liveLessons-page',
            component: () =>
                import('@/views/Teacher/LiveLessonPage.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Canlı Dersler',
                breadcrumb: [{
                    text: 'İçerik İşlemleri',
                    active: true,
                },
                {
                    text: 'Canlı Ders İşlemleri',
                    active: true,
                },
                ],
            },
        },
        {
            path: '/t/optick',
            name: 'teacher-optick-page',
            component: () =>
                import('@/views/Teacher/OptikScanner.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Optik Tarama',
                breadcrumb: [{
                    text: 'Optik İşlemleri',
                    active: true,
                }
                ],
            },
        },
        {
            path: '/t/survey',
            name: 'teacher-survey-page',
            component: () =>
                import('@/views/Teacher/SurveyPage.vue'),
            meta: {
                authRequire: true,
                exportButton: true,
                permission: 'BRANCHADMIN',
                pageTitle: 'Anket Sonuçları',
            },
        },
        {
            path: '/t/chatGroup',
            name: 'teacher-chat-group-page',
            component: () =>
                import('@/views/Teacher/ChatGroup.vue'),
            meta: {
                authRequire: true,
                permission: 'TEACHER',
                pageTitle: 'Mesajlaşma Grupları',
                breadcrumb: [
                    {
                        text: 'Grup İşlemleri',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/r/selection',
            name: 'report-selection-page',
            component: () =>
                import('@/views/Report/ReportSectionPage.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Raporlar',
                breadcrumb: [],
            },
        },
        {
            path: '/r/video',
            name: 'report-video-page',
            component: () =>
                import('@/views/Report/VideoReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Video Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/lack',
            name: 'report-lack-page',
            component: () =>
                import('@/views/Report/LackReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Eksik Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/test',
            name: 'report-test-page',
            component: () =>
                import('@/views/Report/TestReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Test Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/question',
            name: 'report-question-page',
            component: () =>
                import('@/views/Report/QuestionAnswer.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Cevap Anahtarları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/percent',
            name: 'report-percent-page',
            component: () =>
                import('@/views/Report/PercentReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Başarı Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/gains',
            name: 'report-gains-page',
            component: () =>
                import('@/views/Report/GainReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Kazanım Eşleştirme Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/sysVideo',
            name: 'report-system-video-page',
            component: () =>
                import('@/views/Report/SystemVideoReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Video Eşleştirme Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/userWatching',
            name: 'report-user-watching',
            component: () =>
                import('@/views/Report/StudentWatching.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Öğrenci İzleme',
                breadcrumb: [],
            },
        },
        {
            path: '/r/studentTracking',
            name: 'report-user-tracking',
            component: () =>
                import('@/views/Report/StudentTracking.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Öğrenci İzleme',
                breadcrumb: [],
            },
        },
        {
            path: '/r/dailyTest',
            name: 'report-daily-test-page',
            component: () =>
                import('@/views/Report/DailyTestReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Günlük Test Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/digitalHomework',
            name: 'report-digital-homework-page',
            component: () =>
                import('@/views/Report/DigitalHomeworkReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Dijital Ödev Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/studentDetail',
            name: 'report-student-detail-page',
            component: () =>
                import('@/views/Report/StudentDetail.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Öğrenci Detayları',
                breadcrumb: [],
            },
        },
        {
            path: '/r/exam',
            name: 'report-exam-page',
            component: () =>
                import('@/views/Report/ExamReport.vue'),
            meta: {
                authRequire: true,
                isPreviewButton: false,
                isSetupButton: false,
                pageTitle: 'Deneme Sınavı Raporları',
                breadcrumb: [],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () =>
                import('@/views/Login.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/watchingUser/:uid',
            name: 'watchingUser',
            component: () =>
                import('@/views/Report/StudentWatchPopup'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/examReportUser/:userId/:examId',
            name: 'exam-user-report',
            component: () =>
                import('@/views/Report/ExamPopupReport'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () =>
                import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen

router.beforeEach((to, from, next) => {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData == null && to.meta.authRequire) {
        next('login')
    }
    if (to.meta.permission) {
        if (to.meta.permission != userData.userType) {
            next('error-404')
        }
    }
    next()
})
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router